var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',[_vm._v("fas fa-user")])],1),_c('v-toolbar-title',[_vm._v("Relatório mensal de Clientes")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"width":"200","color":"#018454"},on:{"click":function($event){$event.preventDefault();return _vm.download()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("fas fa-file-download")]),_vm._v(" Baixar Relatorio ")],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Baixando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-card',{staticClass:"mt-4",attrs:{"outlined":""}},[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Listagem de Clientes")])],1),_c('v-row',{staticClass:"overflow-y-auto",staticStyle:{"height":"calc(100vh - 363px)"}},[_c('v-col',{staticClass:"px-6 pb-6 pt-0"},[_c('v-data-table',{staticClass:"elevation-1 pt-4",staticStyle:{"color":"black"},attrs:{"color":"#018656","dense":"","headers":_vm.grid.clientes.cabecalho,"items":_vm.grid.clientes.items,"loading":_vm.grid.clientes.carregando,"hide-default-footer":"","items-per-page":_vm.grid.clientes.porPagina,"search":_vm.grid.clientes.pesquisa},scopedSlots:_vm._u([{key:"item.nf",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nota_fiscal))])]}},{key:"item.transp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transportadora_nome))])]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.destinatario_nome))])]}},{key:"item.venda",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.data_venda))])]}},{key:"item.prevista",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.data_prevista))])]}},{key:"item.data_posicao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.data_ultima_posicao))])]}},{key:"item.ultima_posicao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ultima_posicao))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }