<template>
    <article>
      <!-- Início Principal -->
      <v-toolbar dense flat>
        <v-app-bar-nav-icon>
          <v-icon>fas fa-user</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>Relatório mensal de Clientes</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn @click.prevent='download()' width="200" color="#018454" style="color: white;">
          <v-icon class="mr-2" color="white">fas fa-file-download</v-icon>
          Baixar Relatorio
        </v-btn>
      </v-toolbar>
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Baixando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card class="mt-4" outlined>
        <v-row dense justify="center">
          <v-card-title class="text-h6">Listagem de Clientes</v-card-title>


        </v-row>

        <v-row style="height: calc(100vh - 363px);" class="overflow-y-auto">
          <v-col class="px-6 pb-6 pt-0">
            <v-data-table color="#018656" style="color: black;" dense :headers="grid.clientes.cabecalho" :items="grid.clientes.items"
            :loading="grid.clientes.carregando" hide-default-footer class="elevation-1 pt-4" :items-per-page="grid.clientes.porPagina"
            :search="grid.clientes.pesquisa">

              <template #item.nf="{ item }">
                <span>{{ item.nota_fiscal }}</span>
              </template>

              <template #item.transp="{ item }">
                <span>{{ item.transportadora_nome }}</span>
              </template>

              <template #item.nome="{ item }">
                <span>{{ item.destinatario_nome }}</span>
              </template>

              <template #item.venda="{ item }">
                <span>{{ item.data_venda }}</span>
              </template>

              <template #item.prevista="{ item }">
                <span>{{ item.data_prevista }}</span>
              </template>

              <template #item.data_posicao="{ item }">
                <span>{{ item.data_ultima_posicao }}</span>
              </template>

              <template #item.ultima_posicao="{ item }">
                <span>{{ item.ultima_posicao }}</span>
              </template>


              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
    </article>
  </template>

<script crossorigin="anonymous">

import { baseApiUrl, showSuccess, showError } from '@/global'
import axios from 'axios'
import moment from 'moment'
// import saveAs from 'file-saver'

export default {
  name: "Relatorio",
  data: () => ({
    codigo_empresa: '',
    loading: false,
    grid: {
      clientes: {
        cabecalho: [
          { text: 'Nota Fiscal', value: 'nota_fiscal', sortable: false },
          { text: 'Transportadora', value: 'transportadora_nome', sortable: false },
          { text: 'Nome', value: 'destinatario_nome', sortable: false },
          { text: 'Data Venda', value: 'data_venda', sortable: false, formatter: (x) => (x ? moment(x).format('DD/MM/yyyy') : null) },
          { text: 'Data prevista', value: 'data_prevista', sortable: false, formatter: (x) => (x ? moment(x).format('DD/MM/yyyy') : null) },
          { text: 'Data Última Posição', value: 'data_ultima_posicao', sortable: false, formatter: (x) => (x ? moment(x).format('DD/MM/yyyy HH:mm:ss') : null) },
          { text: 'Última Posição', value: 'ultima_posicao', sortable: false },
        ],
        items: []
      }
    }
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
    this.carregarObjetosRelatorio();
  },
  computed: {

  },
  methods: {
    async carregarObjetosRelatorio() {
      let url = `${baseApiUrl}/objetos-relatorio?cod_empresa=${this.codigo_empresa}`;
      await axios.get(url)
        .then((res) => {
          this.grid.clientes.items = res.data.dados
        });
    },
    
    async download() {
      this.loading = true;
      let csvLine = "";
      let date = new Date();
      let url = `${baseApiUrl}/objetos-relatorio?cod_empresa=${this.codigo_empresa}`;
      await axios.get(url)
        .then((res) => {
          this.itemsExportacao = res.data.dados;
          const data = this.itemsExportacao;
          const fileName = '';
          var old = JSON.stringify(this.itemsExportacao); //.replace(/null/g, '""');
          var newArray = JSON.parse(old);
          let csvHead = "Nota Fiscal;Transportadora;Destinatario;Data Venda; Data Prevista;Data Posicao;Ultima Posicao\n";
          newArray.forEach((row) => {
            csvLine = row.nota_fiscal+";"+row.transportadora_nome+";"+row.destinatario_nome+";"+row.data_venda+";";
            csvLine += row.data_prevista+";"+row.data_ultima_posicao+";"+row.ultima_posicao+"\n";
            csvHead += csvLine;
          });
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvHead);
          anchor.target = '_blank';
          anchor.download = 'relatorio_30_'+date.getTime()+'.csv';
          anchor.click();
          this.loading = false;
        });
    }
  }
}
</script>
<style>
.btn-import:hover {
  color: #018454 !important;
}
</style>